.resume {
    width: 45px;
    height: 45px;
    border-radius: 50px;
    border: 1px solid #0e5364;
    background-color: rgb(217, 239, 241);
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 999;
    display: flex;
    align-items: center;
    
    justify-content: center;
}

.resume-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding-top: 2px;
    padding-right: 1px;
}

.toggle-button {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: cadetblue;
    position: absolute;
    cursor: pointer;
    left: 25px;
    transition: 0.5s ease all;
}