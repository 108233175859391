.me {
    display: flex;
    height: 100vh;
}

.me-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.me-right {
    flex: 1;
    position: relative;
}

.me-left-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px;
    height: 50%;
}

.me-intro {
    font-size: 30px;
    font-weight: 300;
}

.me-name {
    font-size: 60px;
}

.me-title {
    height: 50px;
    text-align: center;
    overflow: hidden;
    display: flex;    
}

.me-title-wrapper {
    height: 100%;    
    animation: move 5s ease-in-out infinite alternate;
}

@keyframes move {
    100% {
        transform: translateY(-65px);
    }
    
}

.me-title-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #0e5364;
}

.me-title-item-lole {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #0e5364;
}

.me-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.me-background {
    clip-path: polygon(41% 6%, 100% 15%, 100% 84.5%, 12% 84.5%, 12% 41%);
    background-color: cadetblue;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.me-scroll {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 20px;
}

@media screen and (max-width: 768px) {
    .me {
        flex-direction: column;
    }

    .me-name {
        padding: 10px 0 15px;
    }

    .me-left-wrapper {
        padding: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
    }

    .me-description {
        display: none;
    }

    .me-title-item {
        font-size: 27px;
    }

    .me-title-item-lole {
        font-size: 27px;
    }
}