.about {
    display: flex;
    align-items: center;
    height: 100vh;
}

.about-left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    position: relative;    
    height: 100%;
}

.about-right {
    flex: 1;
    margin-right: 40px;
}

.about-card.bg {
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: cadetblue;
}

.about-card {
    position: relative;
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    overflow: hidden;
}

.about-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about-title {
    font-weight: 400;
}

.about-subtitle {
    margin: 20px 0px;
}

.about-description {
    font-weight: 300;
    text-align: justify;
}

.about-certificate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
}

.about-certificate-img {
    width: 250px;
    height: 175px;
    border-radius: 30px;
}

.about-certificate-text {
    width: 50%;
}

.about-certificate-title {
    font-weight: bold;
    color: #0e5364;
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
    .about {
        flex-direction: column;
        text-align: center;
        padding: 50px 15px 15px 10px;
    }

    .about-left {
        width: 100%;
    }

    .about-right {
        margin: 0px;
    }

    .about-card {
        height: 30vh;
    }

    .about-card.bg , .about-certificate {
        display: none;
    }

    .about-right {
        padding: 20px;
    }
}