.contact {
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
}

.contact-bg {
    width: 20px;
    height: 100%;
    background-color: cadetblue;
    position: absolute;
}

.contact-wrapper {
    padding: 0px 50px;
    display: flex;
    position: relative;
}

/* .contact-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
} */

.contact-left {
    flex: 1;
    padding-left: 20px;
}

.contact-right {
    flex: 1;
    display: flex;
    flex-direction: column;    
    justify-content: center;    
    margin-left: 20px;
}

/* .contact-right {
    flex: 1;
    display: flex;
    flex-direction: column;    
    justify-content: center;
} */

.contact-title {
    font-size: 60px;
    width: 80%;
}

/* .contact-info {

} */

.contact-info-item {
    display: flex;
    align-items: center;
    margin: 30px 0px;
    font-weight: 300;
    width: 70%; 
}

/* .contact-info-item {
    display: flex;
    align-items: center;
    margin: 50px 0px;
    font-weight: 500;
} */

.contact-icon {
    width: 40px;
    height: 40px;
    margin-right: 20px;
}

.contact-description {
    font-weight: 200;    
}

/* .contact-description {
    display: flex;    
    font-weight: 200;
    padding-top: 20px;
} */

form {
    margin-top: 20px;
    position: relative; 
}

input {
    width: 50%;
    height: 50px;
    border: none;
    border-bottom: 1.5px solid #0e5364;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
    position: relative;
}

textarea {
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
    border-color: #0e5364;
    border-radius: 10px;
    padding-top: 11px;
}

/* textarea::placeholder {
    padding-top: 11px;
} */

button {
    border: none;
    border-radius: 10px;
    padding: 15px 20px;
    background-color: cadetblue;
    color: rgb(217, 239, 241);
    font-weight: 500;
    cursor: pointer;
    position: relative;
}

.dMStyle {
    background-color: #266170;
    border-radius: 10px;
    color: white;    
}

.dMStyle::placeholder {
    color: white;
}

span {
    padding: 5px;    
}

@media screen and (max-width: 768px) {
    .contact-wrapper {
        flex-direction: column;
        padding: 0px 50px;        
    }

    .contact-left {
        padding-top: 30px;
    }

    .contact-title {
        font-size: 30px;
    }

    .contact-info-item {
        margin: 20px 0px;
        width: 100%;
    }

    .contact-description {
        display: none;
    }

    form {
        margin-top: 0px;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 15px;
    }

    input {
        width: 35%;
        height: 40px;
        margin: 10px;
        margin-left: 0;
    }

    button {
        margin-top: 10px;
    }

    textarea {
        height: 10vh;
    }
}