.toggle {
    width: 50px;
    height: 25px;
    border-radius: 20px;
    border: 1px solid #0e5364;
    background-color: rgb(217, 239, 241);
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.toggle-icon {
    width: 20px;
    height: 20px;
}

.toggle-button {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: cadetblue;
    position: absolute;
    cursor: pointer;
    left: 25px;
    transition: 0.5s ease all;
}
