.project-container {
    display: flex;
    flex-direction: column;
    width: 45vh;
    margin: 0px 10px;
    position: relative;
}
.project {   
    width: 100%;
    height: 27vh;
    
    border: 2px solid cadetblue;
    border-radius: 7px 7px 0px 0px;
    overflow: hidden;    
}

.project-browser {
    height: 15px;
    background-color: cadetblue;
    display: flex;
    align-items: center;
    padding-left: 3px;
    position: sticky;
    z-index: 2;
}

.project-browser-circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 3px;
    background-color: rgb(217, 239, 241);
}

.project-texts {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.project-img {
   width: 100%;   
   transition: all 10s ease;
}

.project:hover .project-img{
    transform: translateY(-53%);
}

.project-name {
    font-weight: 700;
    color: #0e5364;
    padding: 5px;
    margin: 5px 0px 5px 0px;
}

.project-description {
    font-size: smaller;
    font-weight: 200;
    text-align: justify;
    margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
    .project-description {
        display: none;
    }

    .project {
        width: 25vh;
        height: 17vh;
    }
    
    .project-container {
        align-items: center;
    }
}
